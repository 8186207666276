<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">

          <v-card class="rounded-lg shadow">
            <v-card-title>
              <v-btn color="primary"
                     depressed
                     rounded
                     to="/campaigns">
                <v-icon left>mdi-arrow-left</v-icon>
                Retour
              </v-btn>
              <v-spacer/>
              <div>
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="showCampaign" class="mr-2">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Actualiser</span>
                </v-tooltip>
              </div>
              <div>
                <v-select v-model="campaign_id" dense filled rounded
                          @change="showCampaign"
                          :items="campaigns"
                          prepend-inner-icon="mdi-note-text"
                          item-text="name"
                          item-value="id"
                          placeholder="Campagne"
                          class=" rounded"
                          single-line hide-details></v-select>
              </div>
            </v-card-title>
            <v-divider/>

            <v-card-text v-if="loading">
              <v-skeleton-loader type="date-picker"/>
            </v-card-text>

            <v-card-text v-else>

              <v-row>
                <v-col cols="12" lg="3" v-for="(model,i) in task_models" :key="i">
                  <v-card outlined>
                    <v-card-text>
                      <v-row justify="center" align="center">
                        <v-col cols="8">
                          <v-avatar rounded :color="model.color" size="40">
                            <v-icon v-html="model.icon" dark></v-icon>
                          </v-avatar>
                          <h4 class="mt-2"> {{ model.name }}</h4>
                        </v-col>
                        <v-col class="text-right" cols="4">
                          <h4> {{ model.total }}</h4>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="mb-2" justify="center" align="center">
                <v-col cols="4">
                  <v-btn fab small depressed @click="prev">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="text-center">
                  <h3 class="text-uppercase">{{ title }}</h3>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn fab small depressed @click="next">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-calendar
                  color="primary"
                  @click:event="showEvent"
                  ref="calendar"
                  locale="fr"
                  :events="events"
                  v-model="today"
                  :event-overlap-mode="mode"
              ></v-calendar>

            </v-card-text>

          </v-card>

        </v-col>
      </v-row>
    </v-container>

    <!--Dialogs-->
    <CalendarTaskDialog :dialog.sync="calendarTaskDialog"
                        :task.sync="task"
                        ref="calendarTaskDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import CalendarTaskDialog from "./Dialogs/CalendarTaskDialog";

export default {
  components: {CalendarTaskDialog},
  data() {
    return {
      loading: false,
      campaign: '',
      campaign_id: parseInt(this.$route.params.id),
      mode: 'stack',
      today: '',
      title: '',
      events: [],
      task_models: [
        {name: 'Planogramme', color: 'indigo', icon: 'mdi-image-filter-center-focus-strong-outline', total: 0},
        {name: 'Stock', color: 'primary', icon: 'mdi-clipboard-list-outline', total: 0},
        {name: 'Quiz / Formation', color: 'success', icon: 'mdi-clipboard-file-outline', total: 0},
      ],

      calendarTaskDialog: false,
      task: ''
    }
  },
  methods: {
    next() {
      this.$refs.calendar.next()
      this.title = this.$refs.calendar.title
    },
    prev() {
      this.$refs.calendar.prev()
      this.title = this.$refs.calendar.title
    },
    showCampaign() {
      this.loading = true
      this.$Progress.start()
      this.events = []
      HTTP.get('/campaigns/show/' + this.campaign_id).then((res) => {
        if (res.data.data) {
          this.loading = false
          this.$Progress.finish()
          this.campaign = res.data.data;
          let _vm = this
          this.campaign.tasks.forEach(function (el) {
            let color = ''
            if (!el.task_model) {
              color = 'success'
            }
            if (el.task_model && el.task_model.name === 'Planogramme') {
              color = 'indigo'
            }
            if (el.task_model && el.task_model.name === 'Stock') {
              color = 'primary'
            }
            _vm.events.push({
              id: el.id,
              name: el.name,
              start: el.start_date,
              end: el.end_date,
              color: color,
            })
          })

          this.today = this.events.length ? this.$moment(this.events[0].start).format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD")
          this.task_models[0].total = this.events.filter(el => el.color === 'indigo').length
          this.task_models[1].total = this.events.filter(el => el.color === 'primary').length
          this.task_models[2].total = this.events.filter(el => el.color === 'success').length

          setTimeout(function () {
            _vm.title = _vm.$refs.calendar.title
          }, 100)
        } else {
          this.$router.push('/404')
        }
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    showEvent(el) {
      this.calendarTaskDialog = true
      this.$refs.calendarTaskDialog.showTask(el.event.id)
    }
  },
  created() {
    this.showCampaign()
  },
  computed: {
    campaigns() {
      return this.$store.state.campaigns
    }
  }
}
</script>

<style scoped>

</style>