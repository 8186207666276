<template>
  <div>
    <v-dialog v-model="dialog"
              max-width="1100"
              @click:outside="close"
              scrollable>

      <v-card :loading="loading" :disabled="loading">

        <v-card-title>
          Plus détails de la tâche
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <div v-if="loading">
            <v-row>
              <v-col cols="12" lg="6">
                <v-skeleton-loader type="image"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-skeleton-loader type="paragraph,paragraph,paragraph"/>
              </v-col>
              <v-col cols="12">
                <v-skeleton-loader type="paragraph,paragraph,paragraph"/>
              </v-col>
            </v-row>
          </div>

          <div v-else>

            <v-row>

              <v-col cols="12" lg="6">
                <v-img
                    max-height="400"
                    class="grey lighten-3 rounded-lg"
                    :src="FILE_URL + task.photo"/>
              </v-col>

              <v-col cols="12" lg="6">
                <v-simple-table class="table-border">
                  <template v-slot:default>
                    <tbody>

                    <tr>
                      <td><span class="text--primary font-weight-bold d-flex">Tâche </span></td>
                      <td>{{ task.task_model ? task.task_model.name : 'Quiz / Formation' }}</td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold  d-flex">Nom en FR </span></td>
                      <td>{{ task.name }}</td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold  d-flex">Nom en AR </span></td>
                      <td class="ar">{{ task.name_ar }}</td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold  d-flex">Planification </span></td>
                      <td>{{ task.start_date + '~' + task.end_date }}</td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold  d-flex">Progression </span></td>
                      <td>
                        <div v-if="task.sp_progress">
                          <div class="d-flex justify-space-between">
                            <strong>{{ task.sp_progress.total }}</strong>
                            <v-spacer/>
                            {{ task.sp_progress.progress }} %
                          </div>
                          <v-progress-linear :value="task.sp_progress.progress"
                                             rounded
                                             :color="setColor(task.sp_progress.progress)" height="5"/>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold d-flex">Jours restants</span></td>
                      <td>
                        <div v-if="task.left_days">
                          <v-chip small :color="task.left_days.color" dark outlined>
                            {{ task.left_days.value }}
                          </v-chip>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="text--primary font-weight-bold  d-flex">Points gagnants</span></td>
                      <td>
                        <v-chip dark small color="blue">{{ task.winning_points | toCurrency }}</v-chip>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="font-weight-bold text--primary d-flex">Description en FR</span></td>
                      <td>{{ task.description || '-' }}</td>
                    </tr>

                    <tr>
                      <td><span class="font-weight-bold text--primary d-flex">Description en AR</span></td>
                      <td class="ar">{{ task.description_ar || '-' }}</td>
                    </tr>

                    <tr>
                      <td><span class="font-weight-bold d-flex text--primary">POS Affectées</span></td>
                      <td>
                        <v-chip dark small color="primary">
                          <v-icon left small>mdi-map-marker-outline</v-icon>
                          {{ task.salepoints_count }}
                        </v-chip>
                      </td>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>

                <v-btn block
                       color="blue"
                       class="mt-2"
                       depressed
                       dark
                       :to="'/campaigns/task/'+task.id+'/duplicate'">
                  Dupliquer
                  <v-icon right>mdi-content-duplicate</v-icon>
                </v-btn>

              </v-col>
            </v-row>

            <v-divider class="my-4"/>

            <!--Planogramme -->
            <div v-if="task.task_model && task.task_model.name === 'Planogramme'">
              <div v-for="(question,i) in task.questions" :key="i">
                <v-row>
                  <v-col cols="6">

                    <h4 class="d-block">
                      <span class="text-primary"> Description (FR)</span> <br>
                      {{ question.description }}
                    </h4>

                    <h4 class="d-block mt-2">
                      <span class="text-primary"> Description (AR) :</span> <br>
                      <span class="ar">{{ question.description_ar }}</span>
                    </h4>

                  </v-col>
                  <v-col cols="6">
                    <h4 class="d-block">
                      <span class="text-primary"> Image</span>
                    </h4>
                    <v-img
                        class="grey lighten-3 rounded-lg mt-2"
                        max-height="350"
                        :src="FILE_URL+question.content"/>
                  </v-col>
                </v-row>
              </div>
            </div>

            <!--Stock -->
            <div v-if="task.task_model && task.task_model.name === 'Stock'">
              <div v-for="(question,i) in task.questions" :key="i">
                <v-row>
                  <v-col cols="6">

                    <h4 class="d-block">
                      <span class="text-primary"> Description (FR)</span> <br>
                      {{ question.description }}
                    </h4>

                    <h4 class="d-block mt-2">
                      <span class="text-primary"> Description(AR)</span> <br>
                      <span class="ar">{{ question.description_ar }}</span>
                    </h4>

                  </v-col>
                  <v-col cols="6">
                    <h4 class="d-block">
                      <span class="text-primary"> Conditions</span>
                    </h4>
                    <v-simple-table class="table-border mt-2">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Produit</th>
                          <th>Quantité</th>
                          <th>Box</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(p,i) in question.conditions" :key="i">
                          <td>{{ p.product.name }}</td>
                          <td>{{ p.quantity }}</td>
                          <td>
                            <v-icon v-if="p.is_box" color="primary">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-circle-outline</v-icon>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>
                </v-row>
              </div>
            </div>


            <!--Quiz Formation -->
            <div v-if="!task.task_model">

              <div v-for="(question,i) in task.questions" :key="i">

                <v-row>
                  <v-col cols="6">

                    <h4 class="d-block">
                      <span class="text-primary"> Description (FR)</span> <br>
                      {{ question.description }}
                    </h4>

                    <h4 class="d-block mt-2">
                      <span class="text-primary"> Description (AR)</span> <br>
                      <span class="ar">{{ question.description_ar }}</span>
                    </h4>

                  </v-col>
                  <v-col cols="6">

                    <div v-if="question.question_type && question.question_type.key === 'image'">
                      <h4 class="d-block">
                        <span class="text-primary"> Image</span>
                      </h4>
                      <v-img
                          class="grey lighten-3 rounded-lg mt-2"
                          height="250"
                          :src="FILE_URL+question.content"/>
                    </div>

                    <div v-if="question.question_type && question.question_type.key === 'video'">
                      <h4 class="d-block">
                        <span class="text-primary"> Vidéo</span>
                      </h4>
                      <div class="video-container mt-2">
                        <iframe :src="'//www.youtube.com/embed/'+question.content+'?autoplay=1&mute=1&loop=1'"
                                frameborder="0" allowfullscreen class="video"
                                allow="autoplay; encrypted-media">
                        </iframe>
                      </div>

                    </div>

                    <div v-if="question.question_type && question.question_type.key === 'text'">
                      <h4 class="d-block">
                        <span class="text-primary"> Options</span>
                      </h4>
                      <v-list class="mt-2" dense>
                        <v-list-item v-for="(option, index) in question.options" :key="index">
                          <v-list-item-avatar size="20">
                            <v-icon size="20" v-if="question.answer_type.key === 'radio'">mdi-radiobox-blank</v-icon>
                            <v-icon size="20" v-else>mdi-checkbox-blank-outline</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="option.option"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>

                  </v-col>
                </v-row>
                <v-divider class="my-4"/>
              </div>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['dialog'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      loading: false,
      task: ''
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    showTask(id) {
      this.$Progress.start()
      this.loading = true
      HTTP.get('/campaigns/calendar-task/' + id + '/show').then((res) => {
        this.task = res.data.data
        this.loading = false
        this.$Progress.finish()
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.loading = false
      })
    },
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
    },
  },
}
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>